<template>
  <div v-if="!isInitialLoading && reiseterminObj">
    <Transition name="slide-fade" v-if="reiseterminObj">
      <div
        v-show="isExpanded"
        class="card card-custom"
        style="z-index: 4; position: fixed; width: calc(100% - 2.5rem)"
        :style="{ top: getCompactHeaderTop() }"
      >
        <div class="card-body pb-0 mb-2">
          <div class="d-flex">
            <div>
              <div>
                <span
                  class="d-flex align-items-center flex-wrap text-dark text-nowrap font-size-h5 font-weight-bold mr-3"
                >
                  {{ reiseterminObj.reiseterminkuerzel }}
                </span>
              </div>
              <div class="mb-3 mt-3" style="width: fit-content">
                <ReleaseprozessIcons :reiseterminObject="reiseterminObj || {}" />
              </div>
              <div class="info-section d-flex flex-wrap align-items-center" style="row-gap: 0.5rem">
                <div v-for="(element, index) in filteredDetailsArray" :key="index" class="mr-2">
                  <span v-if="element.label" class="text-muted mx-2">{{ element.label }}:</span>
                  <span v-else></span>
                  <span v-if="element.setting === 'icon'">
                    <span v-if="element.icon === null">-</span>
                    <i v-else :class="element.icon"></i>
                  </span>

                  <span v-if="element.setting === 'paxWidget'" class="d-inline-block">
                    <PaxWidget
                      :paxObject="reiseterminObj.pax"
                      :kalkPax="reiseterminObj.metadata.kalkPax"
                      :erwartetePax="reiseterminObj.metadata.erwartetePax"
                      :durchfuehrbareMinPax="reiseterminObj.metadata.durchfuehrbareMinPax"
                    ></PaxWidget>
                  </span>

                  <span v-if="element.setting === 'metadata.hotelstartVorLandung'">
                    <i
                      :class="{
                        'fas fa-check text-success': reiseterminObj.metadata.hotelstartVorLandung,
                        'fas fa-times text-danger': !reiseterminObj.metadata.hotelstartVorLandung,
                      }"
                    ></i>
                  </span>
                  <span v-if="element.setting === 'metadata.rueckflugNachHotelende'">
                    <i
                      :class="{
                        'fas fa-check text-success': reiseterminObj.metadata.rueckflugNachHotelende,
                        'fas fa-times text-danger': !reiseterminObj.metadata.rueckflugNachHotelende,
                      }"
                    ></i>
                  </span>

                  <span
                    v-if="
                      element.setting === 'transferszenarioHin' || element.setting === 'transferszenarioRueck'
                    "
                  >
                    {{ reiseterminObj.metadata[element.setting].substring(0, 2) || '-' }}
                  </span>

                  <span v-if="element.setting === 'ziellandZielflughaefen'">
                    {{
                      reiseterminObj.metadata.ziellandZielflughaefen
                        .map(fh => fh.flughafenIataCode)
                        .join(', ') || '-'
                    }}
                  </span>
                  <span v-if="element.setting === 'ziellandAbflughaefen'">
                    {{
                      reiseterminObj.metadata.ziellandAbflughaefen
                        .map(fh => fh.flughafenIataCode)
                        .join(', ') || '-'
                    }}
                  </span>
                  <template v-if="element.setting === 'releasefrist'">
                    <div
                      class="d-flex"
                      v-if="
                        reiseterminObj?.metadata?.releasefrist &&
                        reiseterminObj?.metadata?.releasefrist.length > 0
                      "
                    >
                      <span v-for="(releasefrist, idx) in reiseterminObj.metadata.releasefrist" :key="idx">
                        <span class="text-muted mx-2">{{ idx + 1 }}. Releasefrist</span>
                        <span class="mr-2">
                          {{ releasefrist || '-' }} ({{
                            formattedReleasefrist(reiseterminObj.startdatum, releasefrist)
                          }})
                        </span>
                      </span>
                    </div>
                  </template>
                  <span v-if="element.setting === 'data'" class="text-dark-75 font-weight-bolder">
                    {{ element.data || '-' }}
                  </span>
                </div>
              </div>
            </div>

            <div class="d-flex ml-auto">
              <b-button
                v-if="$route.name === 'reisetermine-view'"
                v-allowedRoles="[Role.ADMIN, Role.PM, Role.YIELD]"
                class="btn btn-light-primary btn-icon btn-xl ml-2 mb-2 text-primary text-hover-white"
                aria-label="bearbeiten"
                title="Reise bearbeiten"
                :href="
                  '#/reisen/' +
                  reiseterminObj.reiseterminkuerzel?.substring(0, 6) +
                  '/reisestammdaten?reiseterminAbreisedatum=' +
                  reiseterminObj.abreisedatum.substring(0, 10)
                "
              >
                <i class="flaticon-edit icon-1x" />
              </b-button>

              <b-button
                v-if="$route.name === 'reisetermin-view-releasepruefung'"
                v-allowedRoles="[Role.ADMIN, Role.PM, Role.YIELD, Role.DOM]"
                class="btn btn-light-primary btn-icon btn-xl ml-2 mb-2 text-primary text-hover-white"
                aria-label="bearbeiten"
                title="Reise bearbeiten"
                :to="{
                  name: 'reisen-edit-view',
                  params: { reisekuerzel: reiseterminObj.reise.reisekuerzel },
                }"
              >
                <i class="flaticon-edit icon-1x" />
              </b-button>

              <b-button
                class="btn btn-light-primary btn-icon btn-xl ml-2 mb-2 text-primary text-hover-white"
                aria-label="reiseunterlagen"
                title="Reiseunterlagen"
                :href="
                  'https://www.skr.de/reiseinfos/' +
                  reiseterminObj?.reiseterminkuerzel.replaceAll('-', '').replaceAll('_', '') +
                  '/L/'
                "
                target="_blank"
              >
                <i class="far fa-list-alt fa-lg" />
              </b-button>

              <b-button
                class="btn btn-light-primary btn-icon btn-xl ml-2 mb-2 text-primary text-hover-white"
                aria-label="reisewiki"
                title="Reisewiki"
                :href="'https://skr.de/+' + reiseterminObj.reise.reisekuerzel"
                target="_blank"
              >
                <i class="fab fa-wikipedia-w" />
              </b-button>

              <b-button
                v-if="kontaktdatenlisteLink"
                class="btn btn-light-primary btn-icon btn-xl ml-2 mb-2 text-primary text-hover-white"
                aria-label="kontaktdatenliste"
                title="Kontaktdatenliste"
                :href="kontaktdatenlisteLink"
                target="_blank"
              >
                <i class="flaticon2-user-outline-symbol" />
              </b-button>
            </div>
          </div>
        </div>
        <div @click="onExpandClick" class="py-2 d-block text-center cursor-pointer hover-primary">
          <i class="ki ki-double-arrow-up"></i>
        </div>
      </div>
    </Transition>
    <Transition name="slide-fade">
      <div
        v-show="!isExpanded"
        class="card card-custom gutter-b shadow-sm w-100"
        style="z-index: 2; position: fixed"
        :style="{ top: getCompactHeaderTop() }"
      >
        <div @click="onExpandClick" class="pt-4 pb-2 px-8 d-block text-center cursor-pointer hover-primary">
          <i class="ki" :class="[isExpanded ? 'ki-double-arrow-up' : 'ki-double-arrow-down']"></i>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import PaxWidget from '@/components/produkte/reisetermine/pax-widget.vue';
import ReleaseprozessIcons from '@/components/produkte/reisetermine/releaseprozess-icons.vue';
import { formatDateWithoutHours } from '@/core/common/helpers/utils.js';
import { vorzeitigGarantiertClass } from '@/core/common/helpers/utils';

const moment = require('moment');
import { subDays, format } from 'date-fns';

export default {
  components: {
    PaxWidget,
    ReleaseprozessIcons,
  },
  props: {
    reiseterminObj: Object,
    isInitialLoading: Boolean,
  },
  data() {
    return {
      isExpanded: true,
    };
  },
  computed: {
    kontaktdatenlisteLink() {
      if (!this.reiseterminObj) {
        return null;
      }
      const startdatumYear = new Date(this.reiseterminObj.startdatum).getFullYear();
      return startdatumYear === 2022
        ? 'https://docs.google.com/spreadsheets/d/1jLZQswDFvOvXLT9qlg44QPP5tDp0mcvt5z0PYof_GG8/edit?usp=sharing'
        : startdatumYear === 2023
        ? 'https://docs.google.com/spreadsheets/d/1ISyLNU-nPPi_M7Yrtm_r55Q789Y_9xdpUlRXZpbW-5k/edit#gid=0'
        : startdatumYear === 2024
        ? 'https://docs.google.com/spreadsheets/d/1mf6SBNPv8NNBUulHYNA1XGyKcPxrp3nsRz7Nyx6Har0/edit#gid=0'
        : startdatumYear === 2025
        ? 'https://docs.google.com/spreadsheets/d/1OkrSdCu1SskISUsioOWzJhi1M4lCqhAtScsvcdFMrWo/edit?gid=0#gid=0'
        : null;
    },
    filteredDetailsArray() {
      if (this.$route.name === 'reisetermine-view') {
        // Filtert die Elemente mit hide: true heraus
        return this.detailsArray.filter(element => !element.hide);
      } else {
        // Es werden alle Elemente angezeigt
        return this.detailsArray;
      }
    },
    detailsArray() {
      return [
        {
          label: '',
          setting: 'releasefrist',
          hide: true,
        },
        {
          label: 'Versand der Unterlagen',
          setting: 'data',
          data: this.reiseterminObj?.unterlagenversandDatum
            ? this.$options.filters.date(this.reiseterminObj.unterlagenversandDatum)
            : '-',
          hide: true,
        },
        {
          label: 'Abreise',
          setting: 'data',
          data: this.reiseterminObj?.abreisedatum
            ? this.$options.filters.date(this.reiseterminObj.abreisedatum)
            : '-',
          hide: false,
        },
        {
          label: 'Startdatum',
          setting: 'data',
          data: this.reiseterminObj?.startdatum
            ? this.$options.filters.date(this.reiseterminObj.startdatum)
            : '-',
          hide: false,
        },
        {
          label: 'Reisezeitraum',
          setting: 'data',
          data: this.reiseterminObj?.abreisedatum
            ? `${this.$options.filters.date(
                this.reiseterminObj.abreisedatum
              )} -  ${this.$options.filters.date(
                moment(this.reiseterminObj.abreisedatum).add(
                  this.reiseterminObj?.reisedauer ? this.reiseterminObj?.reisedauer - 1 : 0,
                  'days'
                )
              )}`
            : '-',
          hide: false,
        },
        {
          label: 'Reisedauer',
          setting: 'data',
          data: this.reiseterminObj?.reisedauer ? this.reiseterminObj.reisedauer : '-',
          hide: false,
        },
        { label: 'Pax', setting: 'paxWidget', icon: '', hide: false },
        {
          label: 'Garantiert',
          setting: 'icon',
          icon: this.reiseterminObj?.garantiert ? 'fas fa-check text-success' : 'fas fa-times text-danger',
          hide: false,
        },
        {
          label: 'Vorz. garantiert',
          setting: 'icon',
          icon:
            this.reiseterminObj.vorzeitigGarantiert != null
              ? vorzeitigGarantiertClass(this.reiseterminObj.vorzeitigGarantiert)
              : null,
          hide: false,
        },
        {
          label: 'Stop Sale',
          setting: 'icon',
          icon: this.reiseterminObj?.stopSale ? 'fas fa-exclamation text-danger' : null,
          hide: false,
        },
        {
          label: 'Kritische Destination',
          setting: 'icon',
          icon: this.reiseterminObj?.metadata?.isKritischeDestination
            ? 'fas fa-exclamation text-danger'
            : null,
          hide: true,
        },

        {
          key: 'metadata.overnightOutbound',
          label: 'OB',
          data: this.reiseterminObj?.metadata.overnightOutbound
            ? this.reiseterminObj.metadata.overnightOutbound
            : '-',
          setting: 'data',
          hide: false,
        },
        {
          key: 'metadata.overnightInbound',
          label: 'IB',
          data: this.reiseterminObj?.metadata.overnightInbound
            ? this.reiseterminObj.metadata.overnightInbound
            : '-',
          setting: 'data',
          hide: false,
        },
        {
          key: 'metadata.hotelstartVorLandung',
          label: 'Hotelstart v. Landung',
          setting: 'metadata.hotelstartVorLandung',
          hide: false,
        },
        {
          key: 'metadata.rueckflugNachHotelende',
          label: 'Rückflug n. Hotelende',
          setting: 'metadata.rueckflugNachHotelende',
          hide: false,
        },
        {
          key: 'ziellandZielflughaefen',
          setting: 'ziellandZielflughaefen',
          label: 'ZL Zielflughafen',
          hide: false,
        },
        {
          key: 'ziellandAbflughaefen',
          setting: 'ziellandAbflughaefen',
          label: 'ZL Abflughafen',
          hide: false,
        },
        {
          key: 'metadata.transferszenarioHin',
          label: 'Transferszenario Hin',
          setting: 'transferszenarioHin',
          hide: false,
        },
        {
          key: 'metadata.transferszenarioRueck',
          label: 'Transferszenario Rück',
          setting: 'transferszenarioRueck',
          hide: false,
        },
      ];
    },
  },
  methods: {
    vorzeitigGarantiertClass,
    formatDateWithoutHours,
    getCompactHeaderTop() {
      const subheaderElement = document.getElementById('kt_subheader');
      let mobileHeaderElementBottom = null;
      // If subheader is not found we take 0 as bottom
      const suberheaderElementBottom = subheaderElement ? subheaderElement.getBoundingClientRect().bottom : 0;
      // On mobile it can be below zero so we get the mobile header
      if (suberheaderElementBottom < 0) {
        const mobileHeaderElement = document.getElementById('kt_header_mobile');
        mobileHeaderElementBottom = mobileHeaderElement
          ? mobileHeaderElement.getBoundingClientRect().bottom
          : 0;
      }

      const compactHeaderTop = (mobileHeaderElementBottom || suberheaderElementBottom) - 10;
      return compactHeaderTop + 'px';
    },
    onExpandClick() {
      this.isExpanded = !this.isExpanded;
    },
    formattedReleasefrist(datum, releasefrist) {
      const startdatum = new Date(datum);
      const generatedDatum = subDays(startdatum, releasefrist);
      return format(generatedDatum, 'dd.MM.yyyy');
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transform: translateY(-100px);
  opacity: 0;
  transition: all 0.3s ease-out;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-to {
  transform: translateY(0px);
  opacity: 1;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-100px);
  opacity: 0;
}
</style>
