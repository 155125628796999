var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isInitialLoading && _vm.reiseterminObj)?_c('div',[(_vm.reiseterminObj)?_c('Transition',{attrs:{"name":"slide-fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpanded),expression:"isExpanded"}],staticClass:"card card-custom",staticStyle:{"z-index":"4","position":"fixed","width":"calc(100% - 2.5rem)"},style:({ top: _vm.getCompactHeaderTop() })},[_c('div',{staticClass:"card-body pb-0 mb-2"},[_c('div',{staticClass:"d-flex"},[_c('div',[_c('div',[_c('span',{staticClass:"d-flex align-items-center flex-wrap text-dark text-nowrap font-size-h5 font-weight-bold mr-3"},[_vm._v(" "+_vm._s(_vm.reiseterminObj.reiseterminkuerzel)+" ")])]),_c('div',{staticClass:"mb-3 mt-3",staticStyle:{"width":"fit-content"}},[_c('ReleaseprozessIcons',{attrs:{"reiseterminObject":_vm.reiseterminObj || {}}})],1),_c('div',{staticClass:"info-section d-flex flex-wrap align-items-center",staticStyle:{"row-gap":"0.5rem"}},_vm._l((_vm.filteredDetailsArray),function(element,index){return _c('div',{key:index,staticClass:"mr-2"},[(element.label)?_c('span',{staticClass:"text-muted mx-2"},[_vm._v(_vm._s(element.label)+":")]):_c('span'),(element.setting === 'icon')?_c('span',[(element.icon === null)?_c('span',[_vm._v("-")]):_c('i',{class:element.icon})]):_vm._e(),(element.setting === 'paxWidget')?_c('span',{staticClass:"d-inline-block"},[_c('PaxWidget',{attrs:{"paxObject":_vm.reiseterminObj.pax,"kalkPax":_vm.reiseterminObj.metadata.kalkPax,"erwartetePax":_vm.reiseterminObj.metadata.erwartetePax,"durchfuehrbareMinPax":_vm.reiseterminObj.metadata.durchfuehrbareMinPax}})],1):_vm._e(),(element.setting === 'metadata.hotelstartVorLandung')?_c('span',[_c('i',{class:{
                      'fas fa-check text-success': _vm.reiseterminObj.metadata.hotelstartVorLandung,
                      'fas fa-times text-danger': !_vm.reiseterminObj.metadata.hotelstartVorLandung,
                    }})]):_vm._e(),(element.setting === 'metadata.rueckflugNachHotelende')?_c('span',[_c('i',{class:{
                      'fas fa-check text-success': _vm.reiseterminObj.metadata.rueckflugNachHotelende,
                      'fas fa-times text-danger': !_vm.reiseterminObj.metadata.rueckflugNachHotelende,
                    }})]):_vm._e(),(
                    element.setting === 'transferszenarioHin' || element.setting === 'transferszenarioRueck'
                  )?_c('span',[_vm._v(" "+_vm._s(_vm.reiseterminObj.metadata[element.setting].substring(0, 2) || '-')+" ")]):_vm._e(),(element.setting === 'ziellandZielflughaefen')?_c('span',[_vm._v(" "+_vm._s(_vm.reiseterminObj.metadata.ziellandZielflughaefen .map(fh => fh.flughafenIataCode) .join(', ') || '-')+" ")]):_vm._e(),(element.setting === 'ziellandAbflughaefen')?_c('span',[_vm._v(" "+_vm._s(_vm.reiseterminObj.metadata.ziellandAbflughaefen .map(fh => fh.flughafenIataCode) .join(', ') || '-')+" ")]):_vm._e(),(element.setting === 'releasefrist')?[(
                      _vm.reiseterminObj?.metadata?.releasefrist &&
                      _vm.reiseterminObj?.metadata?.releasefrist.length > 0
                    )?_c('div',{staticClass:"d-flex"},_vm._l((_vm.reiseterminObj.metadata.releasefrist),function(releasefrist,idx){return _c('span',{key:idx},[_c('span',{staticClass:"text-muted mx-2"},[_vm._v(_vm._s(idx + 1)+". Releasefrist")]),_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(releasefrist || '-')+" ("+_vm._s(_vm.formattedReleasefrist(_vm.reiseterminObj.startdatum, releasefrist))+") ")])])}),0):_vm._e()]:_vm._e(),(element.setting === 'data')?_c('span',{staticClass:"text-dark-75 font-weight-bolder"},[_vm._v(" "+_vm._s(element.data || '-')+" ")]):_vm._e()],2)}),0)]),_c('div',{staticClass:"d-flex ml-auto"},[(_vm.$route.name === 'reisetermine-view')?_c('b-button',{directives:[{name:"allowedRoles",rawName:"v-allowedRoles",value:([_vm.Role.ADMIN, _vm.Role.PM, _vm.Role.YIELD]),expression:"[Role.ADMIN, Role.PM, Role.YIELD]"}],staticClass:"btn btn-light-primary btn-icon btn-xl ml-2 mb-2 text-primary text-hover-white",attrs:{"aria-label":"bearbeiten","title":"Reise bearbeiten","href":'#/reisen/' +
                _vm.reiseterminObj.reiseterminkuerzel?.substring(0, 6) +
                '/reisestammdaten?reiseterminAbreisedatum=' +
                _vm.reiseterminObj.abreisedatum.substring(0, 10)}},[_c('i',{staticClass:"flaticon-edit icon-1x"})]):_vm._e(),(_vm.$route.name === 'reisetermin-view-releasepruefung')?_c('b-button',{directives:[{name:"allowedRoles",rawName:"v-allowedRoles",value:([_vm.Role.ADMIN, _vm.Role.PM, _vm.Role.YIELD, _vm.Role.DOM]),expression:"[Role.ADMIN, Role.PM, Role.YIELD, Role.DOM]"}],staticClass:"btn btn-light-primary btn-icon btn-xl ml-2 mb-2 text-primary text-hover-white",attrs:{"aria-label":"bearbeiten","title":"Reise bearbeiten","to":{
                name: 'reisen-edit-view',
                params: { reisekuerzel: _vm.reiseterminObj.reise.reisekuerzel },
              }}},[_c('i',{staticClass:"flaticon-edit icon-1x"})]):_vm._e(),_c('b-button',{staticClass:"btn btn-light-primary btn-icon btn-xl ml-2 mb-2 text-primary text-hover-white",attrs:{"aria-label":"reiseunterlagen","title":"Reiseunterlagen","href":'https://www.skr.de/reiseinfos/' +
                _vm.reiseterminObj?.reiseterminkuerzel.replaceAll('-', '').replaceAll('_', '') +
                '/L/',"target":"_blank"}},[_c('i',{staticClass:"far fa-list-alt fa-lg"})]),_c('b-button',{staticClass:"btn btn-light-primary btn-icon btn-xl ml-2 mb-2 text-primary text-hover-white",attrs:{"aria-label":"reisewiki","title":"Reisewiki","href":'https://skr.de/+' + _vm.reiseterminObj.reise.reisekuerzel,"target":"_blank"}},[_c('i',{staticClass:"fab fa-wikipedia-w"})]),(_vm.kontaktdatenlisteLink)?_c('b-button',{staticClass:"btn btn-light-primary btn-icon btn-xl ml-2 mb-2 text-primary text-hover-white",attrs:{"aria-label":"kontaktdatenliste","title":"Kontaktdatenliste","href":_vm.kontaktdatenlisteLink,"target":"_blank"}},[_c('i',{staticClass:"flaticon2-user-outline-symbol"})]):_vm._e()],1)])]),_c('div',{staticClass:"py-2 d-block text-center cursor-pointer hover-primary",on:{"click":_vm.onExpandClick}},[_c('i',{staticClass:"ki ki-double-arrow-up"})])])]):_vm._e(),_c('Transition',{attrs:{"name":"slide-fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isExpanded),expression:"!isExpanded"}],staticClass:"card card-custom gutter-b shadow-sm w-100",staticStyle:{"z-index":"2","position":"fixed"},style:({ top: _vm.getCompactHeaderTop() })},[_c('div',{staticClass:"pt-4 pb-2 px-8 d-block text-center cursor-pointer hover-primary",on:{"click":_vm.onExpandClick}},[_c('i',{staticClass:"ki",class:[_vm.isExpanded ? 'ki-double-arrow-up' : 'ki-double-arrow-down']})])])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }